import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { sortProposals } from '../redux/actions/proposalsActions';
import Select from '../inputs/Select';
import useLocalStorage from '../hooks/useLocalStorage';

function Sort({ sortProposals }) {
  const [value, setValue] = useLocalStorage('sort', 8);
  const { t } = useTranslation();

  const options = [
    {
      label: `${t('Random')}`,
      value: 8,
    },
    {
      label: `${t('Number - First to last')}`,
      value: 1,
    },
    {
      label: `${t('Number - Last to first')}`,
      value: 2,
    },
    {
      label: `${t('Comments - most')}`,
      value: 3,
    },
    {
      label: `${t('Comments - least')}`,
      value: 4,
    },
    {
      label: `${t('Name - A to Z')}`,
      value: 6,
    },
    {
      label: `${t('Name - Z to A')}`,
      value: 7,
    },
    {
      label: `${t('Classes')}`,
      value: 5,
    },
    // {
    //   label: `${t('Likes - most')}`,
    //   value: 9,
    // },
    // {
    //   label: `${t('Likes - least')}`,
    //   value: 10,
    // },
  ];

  const handleChange = event => {
    setValue(event.target.value);
    sortProposals(event.target.value);
  };

  return (
    <SortByContainer id="sort-by">
      <SortBy className="sm pr-1">{t('Sort by')}:</SortBy>
      <div>
        <Select onChange={handleChange} value={value} options={options} fixedWidth />
      </div>
    </SortByContainer>
  );
}

export default connect(null, { sortProposals })(Sort);

const SortByContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const SortBy = styled('div')(({ theme }) => ({
  color: theme.modeColors.proposalTitle,
  [theme.breakpoints.down('xs')]: {
    marginBottom: '5px',
  },
}));
